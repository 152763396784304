import React from 'react';
import StudioLayout from "../../components/studio/studioLayout"
import Year from '../../components/studio/year';



const Year2016 = () =>
{


    const imageData = [
        {
            name: "p1",
            width: 405,
        },
        {
            name: "p2",
            width: 420,
        },
        {
            name: "p3",
            width: 630,
        },
        {
            name: "p4",
            width: 544,
        },
        {
            name: "p5",
            width: 420,
        },
        {
            name: "p6",
            width: 560,
        },
        {
            name: "p7",
            width: 630,
        },
        {
            name: "p8",
            width: 630,
        },
        {
            name: "p9",
            width: 420,
        },
        {
            name: "p10",
            width: 630,
        },
        {
            name: "p11",
            width: 568,
        },
        {
            name: "p12",
            width: 560,
        },
        {
            name: "p13",
            width: 560,
        },
        {
            name: "p14",
            width: 560,
        },
        {
            name: "p15",
            width: 612,
        },
        {
            name: "p16",
            width: 560,
        },
        {
            name: "p17",
            width: 510,
        },
        {
            name: "p18",
            width: 420,
        },
        {
            name: "p19",
            width: 405,
        },
        {
            name: "p20",
            width: 420,
        },
        {
            name: "p21",
            width: 527,
        },
        {
            name: "p22",
            width: 631,
        },
        {
            name: "p23",
            width: 420,
        },
        {
            name: "p24",
            width: 560,
        },
        {
            name: "p25",
            width: 618,
        },
        {
            name: "p26",
            width: 575,
        },
        {
            name: "p27",
            width: 315,
        },
        {
            name: "p28",
            width: 560,
        },
        {
            name: "p29",
            width: 560,
        },
        {
            name: "p30",
            width: 420,
        },
        {
            name: "p31",
            width: 420,
        },
        {
            name: "p32",
            width: 560,
        },
        {
            name: "p33",
            width: 433,
        },
        {
            name: "p34",
            width: 420,
        },
        {
            name: "p35",
            width: 420,
        },
        {
            name: "p36",
            width: 525,
        },

        {
            name: "p37",
            width: 420,
        },
        {
            name: "p38",
            width: 506,
        },
        {
            name: "p39",
            width: 420,
        },
        {
            name: "p40",
            width: 420,
        },
        {
            name: "p41",
            width: 560,
        },
        {
            name: "p42",
            width: 315,
        },
    ]


    return (
        <StudioLayout year={2016}>
            <Year year={16} imageData={imageData} />
        </StudioLayout>
    );
}

export default Year2016;